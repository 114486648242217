<template>
  <v-container>
    <div class="ma-6">
      <v-flex class="d-flex">
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filter"
            single-line
        ></v-text-field>
      </v-flex>
      <v-data-table
        :headers="headers"
        :items="fwarehouseExpedisiesComputed"
        :search="search"
      >
      <template v-slot:[`item.number`]="{ index }">
        {{index+1}}
      </template>

      <template v-slot:[`item.avatar`]="{ item}">
        <v-img
            :lazy-src="lookupImageUrlLazy(item)"
            :src="lookupImageUrl(item)"
            alt="avatar"
            width="80px"
            height="80px"
            class="ma-2 rounded align-end"
        >

          <v-btn v-if="item.statusActive==false" block x-small class="caption font-weight-black rounded-lg red--text" color="rgb(52,55,235,0.2)" elevation="5" >Disable</v-btn>
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div>
          {{lookupFExpedisi(item.fexpedisiBean)}}
        </div>
        <div class="font-weight-bold blue--text">
          {{lookupFExpedisiLayanan(item.fexpedisiBean)}}
        </div>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <div>
          {{ item.username }}
        </div>
        <div class="font-weight-light caption">
          {{item.apiKey.substring(0, 20)}} ... {{item.apiKey.substr(item.apiKey.length - 20)}}
        </div>
      </template>

      <template v-slot:[`item.originCode`]="{ item }">
        <div class="font-weight-bold subtitle-2">
          {{ item.originCode }}
        </div>
        <div class="font-weight-bold subtitle-2">
          {{ item.originName }}
        </div>
        <div class="font-weight-light caption">
          {{item.olShopBranch }} - {{item.olShopCust }}
        </div>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            x-small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
        <v-btn
            @click="showDialogEdit(item)"
            icon
            class="ml-2"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    </div>

    <FWarehouseExpedisiDialog
        :formMode.sync="formMode"
        :title="title"
        ref="refFormDialog"
        @eventFromFormDialogEdit="saveDataEdit"
    ></FWarehouseExpedisiDialog>

  </v-container>
</template>

<script>

import FWarehouseExpedisiDialog from "./FWarehouseExpedisiDialog";
import FormMode from "../../../models/form-mode";
import FileService from "../../../services/apiservices/file-service";

export default {
  components: {
    FWarehouseExpedisiDialog
  },
  props:{
    itemModified: Object,
    fwarehouseExpedisies: [],
    itemsFExpedisi:[]
  },
  data(){
    return{
      title: '',


      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          filterable: false,
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          filterable: false,
          width: '15%'
        },
        { text: 'Expedisi', value: 'description', width:'25%' },
        { text: 'ORIGIN', value: 'originCode', filterable: false },
        { text: 'Actions', value: 'statusActive', align:'start', filterable: false},
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',

      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed:{

    fwarehouseExpedisiesComputed(){
      return this.fwarehouseExpedisies.map( item =>{
        item.description = this.lookupFExpedisi(item.fexpedisiBean)
        return item
      })
    }

  },
  methods: {

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fwarehouseExpedisies.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)

      this.title = this.lookupFExpedisi(item.fexpedisiBean)

    },
    saveDataEdit(item){
      this.itemSelected = item
      try {
        Object.assign(this.fwarehouseExpedisies[this.itemSelectedIndex], this.itemSelected)
      }catch (e){
        // this.fetchFWarehouse()

        e.return
      }
      this.closeDialog()
    },
    closeDialog () {
      this.formMode = ''
      // this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })
    },

    lookupFExpedisi(fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return `${str[0].description}`
      }else {
        return '-'
      }
    },
    lookupFExpedisiLayanan(fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return `${str[0].kode2}`
      }else {
        return '-'
      }
    },
    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },
    lookupImageUrl(item){
      // console.log(JSON.stringify(item))
      const fexpedisiBean = this.itemsFExpedisi.filter(x => x.id===item.fexpedisiBean)
      // console.log(JSON.stringify())

      if (fexpedisiBean[0].avatarImage===undefined || fexpedisiBean[0].avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(fexpedisiBean[0].avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },


  }

}
</script>

<style scoped>

</style>
