<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>

              <v-row class="blue lighten-3 rounded-lg">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.endPointOrder"
                      label="Order EndPoint"
                      dense
                      outlined
                      hide-details
                      class="blue lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.usernameOrder"
                      label="Order Username"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.passwordOrder"
                      label="Order Password/Key"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.apiKeyOrder"
                      label="Order API Key"
                      prepend-inner-icon="mdi-lock"
                      dense
                  ></v-text-field>
                </v-col>

              </v-row>

              <v-row class="red lighten-4 mt-4 rounded-lg">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.endPointCancelation"
                      label="Cancel-order EndPoint"
                      dense
                      hide-details
                      outlined
                      class="red lighten-3"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.usernameCancelation"
                      label="Cancel-order Username"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.passwordCancelation"
                      label="Cancel-order Password/Key"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.apiKeyCancelation"
                      label="Cancel-order API Key"
                      prepend-inner-icon="mdi-lock"
                      dense
                  ></v-text-field>
                </v-col>

              </v-row>

              <v-row class="green lighten-3  mt-4 rounded-lg">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.endPointTarifCheck"
                      label="Tarif-Check EndPoint"
                      dense
                      outlined
                      hide-details
                      class="green lighten-2"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.usernameTarifCheck"
                      label="Tarif-Check Username"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.passwordTarifCheck"
                      label="Tarif-Check Password/Key"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.apiKeyTarifCheck"
                      label="Tarif-Check API Key"
                      prepend-inner-icon="mdi-lock"
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="blue lighten-5  mt-4 rounded-lg">
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.endPointTrack"
                      label="Track EndPoint"
                      dense
                      outlined
                      class="blue lighten-4"
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.usernameTrack"
                      label="Track Username"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.passwordTrack"
                      label="Track Password/Key"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-model="itemModified.apiKeyTrack"
                      label="Track API Key"
                      prepend-inner-icon="mdi-lock"
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-4 grey lighten-4">
                <v-col
                    cols="12"
                    sm="3"
                    md="3"
                >
                  <v-text-field
                      v-model="itemModified.originCode"
                      label="ORIGIN CODE"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="3"
                    md="3"
                >
                  <v-text-field
                      v-model="itemModified.originName"
                      label="ORIGIN NAME"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="3"
                    md="3"
                >
                  <v-text-field
                      v-model="itemModified.olShopBranch"
                      label="OL-Shop Branch/DistrictId"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="3"
                    md="3"
                >
                  <v-text-field
                      v-model="itemModified.olShopCust"
                      label="OL Shop Cust"
                      dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    sm="2"
                    md="2"
                >
                  <v-switch
                      v-model="itemModified.statusActive"
                      :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
                      x-small
                      class="pa-3"
                  ></v-switch>
                </v-col>
              </v-row>

           </v-container>
          </v-card-text>


          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>


      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <v-snackbar
          v-model="snackbar"
      >
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>


    </v-dialog>
  </div>

</template>

<script>
import FWarehouseExpedisiService from '../../../services/apiservices/f-warehouse-expedisi-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FWarehouseExpedisi from "../../../models/f-warehouse-expedisi";

export default {
  components: { CloseConfirmDialog },
  props:{
    formMode: String,
    title: String
  },
  data() {
    return {
      // title: 'Setting Expedisi',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FWarehouseExpedisi(),

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],


      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

  },
  watch: {
  },

  methods: {


    showDialog(selectedIndex, item) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FWarehouseExpedisi(0, '', ''),
        this.itemModified =  new FWarehouseExpedisi(0, '', ''),
        this.selectedIndex = -1
      }

    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {
          FWarehouseExpedisiService.updateFWarehouseExpedisi(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemModified)
              },
              error => {
                // console.log(error);
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )

        }else {
          // FWarehouseExpedisiService.createFWarehouseExpedisi(this.itemModified).then(
          //     response =>{
          //       this.$emit('eventFromFormDialogNew', response.data)
          //     },
          //     error => {
          //       this.formDialogOptions.errorMessage = error.response.data.message
          //     }
          // )
        }
      }
    },

    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      this.formDialogOptions.errorMessage = ''

      FWarehouseExpedisiService.getFWarehouseExpedisiById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },

  }

}
</script>

<style scoped>
</style>
