<template>
  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>

    </v-card-title>

    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fWarehousesFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
        align="start"
    >
      <template v-slot:top v-if="false">
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect"
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.number`]="{ index }">
        {{ (index + ((currentPage-1)*pageSize) +1 )}}
      </template>


      <template v-slot:[`item.avatar`]="{ item}">
        <v-img
            :lazy-src="lookupImageUrlLazy(item)"
            :src="lookupImageUrl(item)"
            alt="avatar"
            width="80px"
            height="80px"
            class="ma-2 rounded"
        >
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-2">
          {{item.description}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{item.kode1}}
        </div>
        <div v-if="item.npwp" class="caption small">
          NPWP <span class="font-italic">{{item.npwp}}</span>
        </div>
      </template>

      <template v-slot:[`item.address1`]="{ item }">
        <div class="fill-height">
          <div>
            {{ item.address1 }}
          </div>
          <div class="caption small" v-if="item.address2">
            {{ item.address2 }}
          </div>
          <div>
            {{ item.district1 }} - <span class="subtitle-2">{{ item.city1 }}</span>
          </div>
          <div v-if="item.phone || item.email" >
            <span v-if="item.phone"><v-icon x-small>mdi-phone</v-icon>+{{item.countryCode}} {{ item.phone }}</span>
            <span class="ml-1 caption" v-if="item.email"><v-icon x-small>mdi-email</v-icon>{{item.email}}</span>
          </div>
        </div>

      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                small
            >
              {{ item.statusActive==true?'Aktif': '-' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive==true?'Aktif': '-' }}
        </v-chip>
      </template>


      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>


    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteDialog>

    <FWarehouseDialog
        :formMode.sync="formMode"
        :itemsFExpedisi="itemsFExpedisi"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FWarehouseDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>

<script>
import FWarehouseService from '../../../services/apiservices/f-warehouse-service';
import FDivisionService from "../../../services/apiservices/f-division-service";
import FileService from "../../../services/apiservices/file-service"
import FExpedisiService from '../../../services/apiservices/f-expedisi-service'


import DeleteDialog from "../../../components/utils/DeleteConfirmDialog";
import FWarehouseDialog from "./FWarehouseDialog";
import FormMode from "../../../models/form-mode";
import FWarehouse from '../../../models/f-warehouse'
import FDivision from '../../../models/f-division'

import {format, parseISO} from "date-fns";


export default {
  components: { FWarehouseDialog, DeleteDialog},
  data () {
    return {
      title: 'WAREHOUSE',

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'avatar',
          width: '20%'
        },
        { text: 'Warehouse', value: 'description', width:'30%' },
        { text: 'Address', value: 'address1', width: '35%' },
        { text: 'Branch/Distributor', value: 'fdivisionBean' },
        // { text: 'Actions', value: 'actions', align:'start', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fWarehouses:[
          new FWarehouse(1, "001")
      ],
      itemsFDivision: [
          new FDivision()
      ],
      itemsFExpedisi: [],

    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFWarehouse()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFWarehouse()
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      }
    },
  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    fWarehousesFiltered(){
      return this.fWarehouses
    }
  },
  methods: {

    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFWarehouse()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

      FExpedisiService.getAllFExpedisi().then(
          response =>{
            this.itemsFExpedisi = response.data
            // console.log(`Hello sini ${this.itemsFWarehouseSalesBrand}`)
          },
          error=>{
            console.log(error.response)
          }
      )

    },
    fetchFWarehouse() {
      // console.log(`Masuk fetchFWarehouse`)
      FWarehouseService.getAllFWarehouseContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {
            // console.log(JSON.stringify(response.data.items))

            const { items, totalPages} = response.data
            this.fWarehouses = items
            this.totalPaginationPages = totalPages

            console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    showDialogNew() {
      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision, this.itemsWarehouseType,
      //     this.itemsFWarehouseSalesBrand, this.itemsFWarehouseGroup3, this.itemsFWarehouse, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fBeritas.push(this.itemSelected)
      // this.fBeritas = [itemFromRest].concat(this.fBeritas)
      // this.fBeritas.unshift(itemFromRest)
      this.fetchFWarehouse()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fWarehousesFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      this.formMode = FormMode.EDIT_FORM
      // this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision,  this.itemsWarehouseType,
      //     this.itemsFWarehouseSalesBrand, this.itemsFWarehouseGroup3, this.itemsFWarehouse, this.itemsFWarehouse, this.itemsFPegawai)
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
        this.itemSelected = item
        try {
          Object.assign(this.fWarehouses[this.itemSelectedIndex], this.itemSelected)
        }catch (e){
          this.fetchFWarehouse()
          e.return
        }
      this.closeDialog()

    },


    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fWarehousesFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fWarehouses[this.itemSelectedIndex]
      FWarehouseService.deleteFWarehouse(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fWarehouses.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FWarehouseService.deleteAllFWarehouse(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fWarehousesFiltered.indexOf(items[i])
                      this.fWarehouses.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    // console.log("Undeleted Items: " + response.data + " = " + items[i].id)

                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FWarehouseService.deleteAllFWarehouses()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FWarehouse())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    onImgError: function(event) {
      /**
       * masih belum fungsi
       */
      console.log(event)
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      // return "Rp." + parseFloat(value).toFixed(0).toLocaleString()
      return prefix + "" + parseFloat(value).toLocaleString()
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchFWarehouse()
      this.fetchParent()

    }


  }

}
</script>

<style scoped>

</style>
