export default class FWarehouseExpedisi {
  constructor(
    id,
    description,
    statusActive,

    fdivisionBean,
    fexpedisiBean,

    endPointOrder,
    usernameOrder,
    passwordOrder,
    apiKeyOrder,

    endPointCancelation,
    usernameCancelation,
    passwordCancelation,
    apiKeyCancelation,

    endPointTarifCheck,
    usernameTarifCheck,
    passwordTarifCheck,
    apiKeyTarifCheck,

    endPointTrack,
    usernameTrack,
    passwordTrack,
    apiKeyTrack,

    originCode,
    originName,
    olShopBranch,
    olShopCust,


    sourceId,
    created,
    modified,
    modifiedBy
  ) {

    this.id = id;
    this.description = description;
    this.statusActive = statusActive;

    this.fdivisionBean = fdivisionBean;
    this.fexpedisiBean = fexpedisiBean;

    this.endPointOrder = endPointOrder;
    this.usernameOrder=usernameOrder;
    this.passwordOrder=passwordOrder;
    this.apiKeyOrder=apiKeyOrder;

    this.endPointCancelation = endPointCancelation;
    this.usernameCancelation=usernameCancelation;
    this.passwordCancelation=passwordCancelation;
    this.apiKeyCancelation=apiKeyCancelation;

    this.endPointTarifCheck = endPointTarifCheck;
    this.usernameTarifCheck=usernameTarifCheck;
    this.passwordTarifCheck=passwordTarifCheck;
    this.apiKeyTarifCheck=apiKeyTarifCheck;

    this.endPointTrack = endPointTrack;
    this.usernameTrack=usernameTrack;
    this.passwordTrack=passwordTrack;
    this.apiKeyTrack=apiKeyTrack;

    this.originCode = originCode;
    this.originName = originName;
    this.olShopBranch = olShopBranch;
    this.olShopCust = olShopCust;

    this.sourceId = sourceId;
    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
