var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ma-6"},[_c('v-flex',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fwarehouseExpedisiesComputed,"search":_vm.search},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+" ")]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-2 rounded align-end",attrs:{"lazy-src":_vm.lookupImageUrlLazy(item),"src":_vm.lookupImageUrl(item),"alt":"avatar","width":"80px","height":"80px"}},[(item.statusActive==false)?_c('v-btn',{staticClass:"caption font-weight-black rounded-lg red--text",attrs:{"block":"","x-small":"","color":"rgb(52,55,235,0.2)","elevation":"5"}},[_vm._v("Disable")]):_vm._e()],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.lookupFExpedisi(item.fexpedisiBean))+" ")]),_c('div',{staticClass:"font-weight-bold blue--text"},[_vm._v(" "+_vm._s(_vm.lookupFExpedisiLayanan(item.fexpedisiBean))+" ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.username)+" ")]),_c('div',{staticClass:"font-weight-light caption"},[_vm._v(" "+_vm._s(item.apiKey.substring(0, 20))+" ... "+_vm._s(item.apiKey.substr(item.apiKey.length - 20))+" ")])]}},{key:"item.originCode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(" "+_vm._s(item.originCode)+" ")]),_c('div',{staticClass:"font-weight-bold subtitle-2"},[_vm._v(" "+_vm._s(item.originName)+" ")]),_c('div',{staticClass:"font-weight-light caption"},[_vm._v(" "+_vm._s(item.olShopBranch)+" - "+_vm._s(item.olShopCust)+" ")])]}},{key:"item.statusActive",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.statusActive),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.statusActive==true?'Aktif': '-')+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)})],1),_c('FWarehouseExpedisiDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode,"title":_vm.title},on:{"update:formMode":function($event){_vm.formMode=$event},"update:form-mode":function($event){_vm.formMode=$event},"eventFromFormDialogEdit":_vm.saveDataEdit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }