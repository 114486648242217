export default class FWarehouse {
  constructor(
    id,
    kode1,
    description,
    avatarImage,
    fdivisionBean,
    statusActive=true,

    senderName,

    address1,
    address2,
    district1,
    city1,
    state1,
    phone,
    countryCode=62,

    zipCode,
    email,

    lat,
    lon,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;

    this.senderName = senderName;
    this.address1 = address1;
    this.address2 = address2;
    this.district1 = district1;
    this.city1 = city1;
    this.state1 = state1;
    this.phone = phone;
    this.countryCode = countryCode;

    this.zipCode =zipCode;
    this.email  =email;

    this.lat = lat;
    this.lon = lon;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
