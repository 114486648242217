<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabWarehouseClick">
        WAREHOUSE
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FWarehouseTable ref="refTabWarehouseClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import FWarehouseTable from "../../components/inventory/warehouse/FWarehouseTable";

export default {
  name: "WarehouseView",
  components: { FWarehouseTable },
  data() {
    return {
      firstTabTitle: 'WAREHOUSE',
      firstTabIcon: 'mdi-home-variant'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabWarehouseClick(){
      this.$refs.refTabWarehouseClick.fetchParent()
    },

  }
}
</script>

<style scoped>
</style>